<template>
  <div class="main-box">
    <div class="title-main">PIR Config</div>
    <div class="flex-row-start-between line-box">
      <div><!--占位--></div>
      <a-button @click="refreshAll">Refresh All</a-button>
    </div>

    <!-- env tabs begin -->
    <a-tabs class="my-tabs" :loading="loading" :lazy-load="true" :animation="true">
      <a-tab-pane v-for="(envOption) in envOptions" :key="envOption.id" :value="envOption.id"
                  :title="envOption.title">
        <!-- node tabs begin -->
        <a-tabs class="my-tabs" v-if="mapData[envOption.id]" :lazy-load="true" :animation="true">
          <a-tab-pane v-for="(pirConfig) in mapData[envOption.id]" :key="pirConfig.nodeId" :value="pirConfig.nodeId"
                      :title="pirConfig.nodeName">
            <!--
            <div style="background-color: white;">
              <pre>{{ JSON.stringify(pirConfig, null, 4) }}</pre>
            </div>
            -->
            <!-- s3Config -->
            <div v-if="pirConfig.params.s3Config" class="panel-box">
              <div class="panel-title">Video Store</div>
              <div class="flex-row-start-center line-box">
                <span class="line-key">S3 Access Key:</span>
                <span class="line-value">
                <a-input v-model="pirConfig.params.s3Config.s3AccessKey" placeholder=""></a-input>
              </span>
              </div>
              <div class="flex-row-start-center line-box">
                <span class="line-key">S3 Secret Key:</span>
                <span class="line-value">
                <a-input v-model="pirConfig.params.s3Config.s3SecretKey" placeholder=""></a-input>
              </span>
              </div>
              <div class="flex-row-start-center line-box">
                <span class="line-key">S3 Region:</span>
                <span class="line-value">
                <a-input v-model="pirConfig.params.s3Config.clientRegion" placeholder=""></a-input>
              </span>
              </div>
            </div>
            <!-- pirUploads -->
            <div v-if="pirConfig.params.pirUploads" class="panel-box">
              <div class="panel-title">File Name Template</div>
              <div class="flex-row-start-center line-box">
                <span class="line-key">Motion Image:</span>
                <span class="line-value">
                <a-input v-model="pirConfig.params.pirUploads.motionImage.postfixTemplate" placeholder=""></a-input>
              </span>
              </div>
              <div class="flex-row-start-center line-box">
                <span class="line-key">Cover Image:</span>
                <span class="line-value">
                <a-input v-model="pirConfig.params.pirUploads.coverImage.postfixTemplate" placeholder=""></a-input>
              </span>
              </div>
              <div class="flex-row-start-center line-box">
                <span class="line-key">Video Slice:</span>
                <span class="line-value">
                <a-input v-model="pirConfig.params.pirUploads.video.postfixTemplate" placeholder=""></a-input>
              </span>
              </div>
            </div>
            <!-- videoSlice -->
            <div v-if="pirConfig.params.videoSlice" class="panel-box">
              <div class="panel-title">Video Upload</div>
              <div class="flex-row-start-center line-box">
                <span class="line-key">Assume Role Arn:</span>
                <span class="line-value">
                <a-input v-model="pirConfig.params.videoSlice.roleArn" placeholder=""></a-input>
                  </span>
              </div>
              <div class="flex-row-start-center line-box">
                <span class="line-key">Assume Role Duration:</span>
                <span class="line-value" style="width:100px;">
                  <a-input-number v-model="pirConfig.params.videoSlice.durationSeconds"
                                  placeholder="" :min="300" :max="86400" /> <!-- 5min~1day -->
                </span>
                <span class="line-value" style="width: auto;padding-left: 10px;">seconds</span>
              </div>
              <div class="flex-row-start-center line-box">
                <span class="line-key">S3 Notify Cert Endpoint:</span>
                <span class="line-value">
                    <a-input v-model="pirConfig.params.videoSlice.awsCertEndpoint" placeholder=""></a-input>
                </span>
              </div>
              <div class="flex-row-start-center line-box">
                <span class="line-key">S3 Accelerate Enable:</span>
                <span class="line-value">
                <a-switch class="my-switch" v-model="pirConfig.params.videoSlice.deviceS3AccelerateEnable">
                  <template #checked><span class="text-switch">ON</span></template>
                  <template #unchecked><span class="text-switch">OFF</span></template>
                </a-switch>
              </span>
              </div>
            </div>

            <div class="flex-row-start-center line-box">
              <a-space>
                <a-button @click="refreshPirConfig(pirConfig)">Refresh</a-button>
                <a-button @click="savePirConfig(pirConfig)">Save</a-button>
              </a-space>
            </div>

          </a-tab-pane>
        </a-tabs>
        <!-- node tabs end -->
      </a-tab-pane>
    </a-tabs>
    <!-- env tabs end -->

  </div>
</template>

<script>
import { queryPirConfig, putPirConfig, getPirConfig } from "@/api/iot-essence";

const emptyPirConfig = {
  s3Config: {
    s3AccessKey: "", // 'AKIATGITAJO62TJ7EQG6'
    s3SecretKey: "", // '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'
    clientRegion: "" // 'us-east-1'
  },
  pirUploads: {
    motionImage: { postfixTemplate: "" }, // 'nvc_${eventTs}_${snapTs}.jpeg'
    coverImage: { postfixTemplate: "" }, // 'image.${imgType}'
    video: { postfixTemplate: "" } // 'slice_${period}_${order}_${isLast}.ts'
  },
  videoSlice: {
    roleArn: "", // 'arn:aws:iam::219620068285:role/addx-prod-role'
    durationSeconds: 43200, // 43200
    awsCertEndpoint: "", // sns.us-east-1.amazonaws.com.cn
    deviceS3AccelerateEnable: false // false
  }
};

export default {
  data() {
    return {
      accountId: null,
      tenantId: null,
      listData: [],
      loading: true
    };
  },
  async created() {
    this.accountId = this.$route.params.accountId;
    this.tenantId = this.$route.params.tenantId;
    await this.refreshAll();
  },
  methods: {
    async refreshAll() {
      this.loading = false;
      const records = await queryPirConfig(this.tenantId);
      console.log("pirConfig refreshAll:", records);
      this.listData = records;
      this.loading = false;
    },
    groupByEnvIdAndNodeId(records) {
      const envMap = {};
      for (const record of records) {
        const nodeList = envMap[record.envId] || (envMap[record.envId] = []);
        nodeList.push(record);
      }
      return envMap;
    },
    async refreshPirConfig(record) {
      this.loading = true;
      const data = await getPirConfig(record.nodeId, record.tenantId);
      console.log("refreshPirConfig:", data);
      if (data) {
        // this.listData = this.listData.map(it => it.nodeId === data.nodeId ? data : it);
        for (const it of this.listData) {
          if (it.nodeId === data.nodeId) {
            this.copyFields(data, it);
          }
        }
      }
      this.loading = false;
    },
    async savePirConfig(record) {
      this.loading = true;
      await putPirConfig(record);
      this.loading = false;
    }
  },
  computed: {
    mapData() {
      return this.groupByEnvIdAndNodeId(this.listData);
    }
  }
};
</script>

<style lang="scss" scoped>
.line-key {
  width: 160px;
}

.my-switch {
  background: #F97846;
}
</style>

